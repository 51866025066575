import React from "react";

const TopNavComponent = (props) => {
  return (
    <div className="topNav">
      <ul className="topLinks">
        <li>
          <a href="/weduc-login">
            <img src="/Weduc.png" alt="Weduc Login" />
          </a>
        </li>
        <li>
          <a
            href="https://www.parentpay.com/schools/our-product/"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img src="/ParentPay.png" alt="Parent Pay" />
          </a>
        </li>
        <li>
          <a
            href="https://teams.microsoft.com/"
            target="_blank"
            rel="external noopener noreferer"
          >
            <img src="/teams.png" alt="Teams" />
          </a>
        </li>
      </ul>
      <ul>
        <li className="search-top">
          <form action="/search/" method="get">
            <button type="submit"></button>
            <input name="term" id="Search" placeholder="Search"></input>
          </form>
        </li>
      </ul>
    </div>
  );
};

export default TopNavComponent;
